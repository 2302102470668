.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.inputWidthInlineFlex{
  width: 160px;
  padding-right: 1rem!important;
  display: inline-flex
}

.arrayInputItemText{
  width: 260px;
  display: inline-flex;
  padding-right: 4px;
}

.arrayInputNumber{
  width: 60px;
  display: inline-flex
}

.energyWeightInput{
  width: 120px;
  display: inline-flex;
  padding-right: 1rem!important;
}

.a{
  padding-left: 0px!important;
  padding-bottom: 6px!important;
  min-width: 30px !important;
}


