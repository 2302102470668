.label {
    display: inline-flex;
    width: 170px;
}


.form-line {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.field {

}

.form {
    padding: 15px 30px 15px 30px;
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    border-radius: 10px;
    width: min-content;
    margin: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.button {
    margin-top: 15px;
    padding: 0.3em 1.2em;
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    background-color: #4eb5f1;
    text-align: center;
    transition: all 0.2s;
}

.button:hover {
    background-color: #4095c6;
}

body {
    height: 100vh
}

.form-wrapper {
    display: flex;
    height: 100vh;
    justify-content: center;
}

.err-msg {
    color: #FC4A4A;
    border: 1px solid #FC4A4A;
    text-align: center;
    border-radius: 10px;
    padding: 3px;
}

.ok-msg{
    color: yellowgreen;
    border: 1px solid yellowgreen;
    text-align: center;
    border-radius: 10px;
    padding: 3px;
}

.back-link{
    margin-top: 15px;
    text-decoration: none;
}